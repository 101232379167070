let s3 = {
  REGION: "us-east-2",
  BUCKET: "facilitychex-images"
};
let cognito = {
  REGION: "us-east-2",
  USER_POOL_ID: "us-east-2_AfJIlvhgM",
  APP_CLIENT_ID: "7fd742rc9mbblibhf70ti56638",
  IDENTITY_POOL_ID: "us-east-2:2cb0773a-d70b-4467-b2f1-17ec02bba7c3"
};
let gateway = {
  REGION: "us-east-2"
};

if (process.env.REACT_APP_STAGE === 'prod') {
  gateway.URL = null;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  gateway.URL = "https://lhie07i2xl.execute-api.us-east-2.amazonaws.com/dev/";
} else {
  gateway.URL = "https://lhie07i2xl.execute-api.us-east-2.amazonaws.com/dev/";
}

export default {
  cognito: cognito,
  apiGateway: gateway,
  s3: s3
};
