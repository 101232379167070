import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";

// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/logo.svg";

function Login(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");
  var [activeTabId, setActiveTabId] = useState(0);
  var [email, setEmail] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [phone, setPhone] = useState("");
  var [phoneErr, setPhoneErr] = useState(false);
  var [role, setRole] = useState("");
  var [storeNum, setStoreNum] = useState("");
  var [storeNumErr, setStoreNumErr] = useState(false);
  var [name, setName] = useState("");

  // TODO: put this in DDB
  const admins = ["mc.bhaulers@gmail.com"];
  const haulers = {
    "Ben Tire": ["bentirerecycling@gmail.com"],
    "Black to Green": ["dgrento16@gmail.com"],
    Emanuel: ["jlee@emanueltire.com"],
    "Best Haulers": [],
    Liberty: ["ferguson@libertytire.com"],
    "Liberty Lakin": ["yanitsanchez@lakintire.com", "lost75703@hotmail.com"],
    tyrex: ["m.delia@tyrexresources.com", "y.chavez@tyrexresources.com"],
    "S&J Recycling Inc": ["sjrecycling@aol.com"],
  };
  const acct_mngrs = {
    "Diana Hiltz": "dhiltz@mavistire.com",
    "Maria Vacacela": "mvacacela@mavistire.com",
    "Elias Garcia": "egarcia@mavistire.com",
    "Daniel Guido": "dguido@mavistire.com",
    "Susan Kniser": "skniser@mavistire.com",
    "Gary Mitchell": "gmitchell@mavistire.com",
    "Pat Dalman": "pdalman@mavistire.com",
    "John Marcella": "jmarcella@mavistire.com",
    "Andrew Dowdell": "adowdell@mavistire.com",
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>FacilityChex</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Fade in={error ? true : false}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              <form>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email"
                  type="email"
                  fullWidth
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      disabled={
                        email.length === 0 || passwordValue.length === 0
                      }
                      onClick={async () => {
                        setIsLoading(true);
                        try {
                          await Auth.signIn(email, passwordValue);
                          props.userHasAuthenticated(true);
                        } catch (e) {
                          setError(e.message);
                          setIsLoading(false);
                        }
                      }}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                  <Button
                    color="primary"
                    size="large"
                    className={classes.forgetButton}
                    onClick={() => props.history.push("/forgot")}
                  >
                    Forgot Password
                  </Button>
                </div>
              </form>
            </React.Fragment>
          )}

          {activeTabId === 1 && (
            <React.Fragment>
              <Fade in={error ? true : false}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={4} style={{ fontSize: "1.15rem" }}>
                    <FormControl
                      style={{ minWidth: 100 }}
                      className={classes.formControl}
                    >
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"account_manager"}>Acct Mngr</MenuItem>
                        <MenuItem value={"hauler"}>Hauler</MenuItem>
                        <MenuItem value={"store_manager"}>Store Mngr</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {role === "store_manager" && (
                    <Grid item xs={4} style={{ fontSize: "1.15rem" }}>
                      <TextField
                        label="Store#"
                        error={storeNumErr}
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={storeNum}
                        onChange={(e) => setStoreNum(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {role === "hauler" && (
                    <Grid item xs={6} style={{ fontSize: "1.15rem" }}>
                      <FormControl
                        style={{ minWidth: 140 }}
                        className={classes.formControl}
                      >
                        <InputLabel>Hauler Name</InputLabel>
                        <Select
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        >
                          {Object.keys(haulers).map((hauler) => (
                            <MenuItem key={hauler} value={hauler}>
                              {hauler}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {role === "account_manager" && (
                    <Grid item xs={6} style={{ fontSize: "1.15rem" }}>
                      <FormControl
                        style={{ minWidth: 140 }}
                        className={classes.formControl}
                      >
                        <InputLabel>Name</InputLabel>
                        <Select
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        >
                          {Object.keys(acct_mngrs).map((mngr) => (
                            <MenuItem key={mngr} value={mngr}>
                              {mngr}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  placeholder="Email"
                  type="email"
                  fullWidth
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
                {role === "hauler" && (
                  <TextField
                    error={phoneErr}
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    margin="normal"
                    placeholder="Mobile phone #"
                    fullWidth
                  />
                )}
                <div className={classes.creatingButtonContainer}>
                  {isLoading ? (
                    <CircularProgress size={26} />
                  ) : (
                    <Button
                      onClick={async (e) => {
                        e.preventDefault(); // prevent reload

                        setError("");
                        setStoreNumErr(false);
                        setPhoneErr(false);
                        setIsLoading(true);

                        try {
                          // verify email address for roles
                          if (role === "hauler") {
                            if (!haulers[name].includes(email.toLowerCase()))
                              throw new Error("Invalid email.");
                          } else if (role === "account_manager") {
                            if (acct_mngrs[name] !== email.toLowerCase())
                              throw new Error("Invalid email.");
                          } else if (role === "admin") {
                            if (!admins.includes(email.toLowerCase()))
                              throw new Error("Invalid email.");
                          }

                          if (role === "hauler") {
                            if (phone.replace(/\D+/g, "").length !== 10) {
                              setPhoneErr(true);
                              throw new Error("Invalid phone number.");
                            }
                          }

                          const data = {
                            username: email,
                            password: passwordValue,
                            attributes: {
                              "custom:custom:role": role,
                            },
                          };
                          if (role === "store_manager" && storeNum) {
                            const store = await API.get(
                              "api",
                              "stores/" + storeNum.padStart(4, "0")
                            );
                            if (store) {
                              data.attributes["custom:custom:role_details"] =
                                storeNum.padStart(4, "0");
                            } else {
                              setStoreNumErr(true);
                              setIsLoading(false);
                              throw new Error("Invalid store number.");
                            }
                          }
                          if (
                            (role === "hauler" || role === "account_manager") &&
                            name
                          )
                            data.attributes["custom:custom:role_details"] =
                              name;
                          if (role === "hauler" && phone)
                            data.attributes["custom:phone"] = phone.replace(
                              /\D+/g,
                              ""
                            );

                          await Auth.signUp(data);
                          await Auth.signIn(email, passwordValue);
                          props.userHasAuthenticated(true);
                        } catch (e) {
                          setError(e.message);
                          setIsLoading(false);
                        }
                      }}
                      disabled={
                        email.length === 0 ||
                        passwordValue.length === 0 ||
                        role === "" ||
                        (role === "store_manager" && storeNum === "") ||
                        (role === "hauler" && name === "") ||
                        (role === "hauler" && phone === "") ||
                        (role === "account_manager" && name === "")
                      }
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                      type="submit"
                    >
                      Create account
                    </Button>
                  )}
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 FacilityChex. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
