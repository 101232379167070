import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API } from "aws-amplify";

// styles
import useStyles from "./styles";

export default function Create(props) {
  const classes = useStyles();

  //state
  const [numTireIn, setNumTireIn] = useState("");
  const [numTireOut, setNumTireOut] = useState("");
  const [tireInErr, setTireInErr] = useState(false);
  const [tireOutErr, setTireOutErr] = useState(false);
  const [storeNum, setStoreNum] = useState("");
  const [storeNumErr, setStoreNumErr] = useState(false);
  const [note, setNote] = useState("");
  const [openTicketErr, setOpenTicketErr] = useState(false);
  const [openTicketId, setOpenTicketId] = useState(null);
  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);
  const [legacyId, setLegacyId] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // init
  useEffect(() => {
    if (!props.cognito) return; // wait for cognito

    // auto fill store number if store manager
    if (props.cognito.role === "store_manager")
      setStoreNum(props.cognito.role_details);
  }, [props.cognito]);

  function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  function handleCreate() {
    // reset form errors
    setDateErr(false);
    setTireInErr(false);
    setTireOutErr(false);
    setStoreNumErr(false);
    setOpenTicketErr(false);

    // check store num
    if (!storeNum) {
      setStoreNumErr(true);
      return;
    }

    // get date
    if (
      !date ||
      date.toString() === "Invalid Date" ||
      date > new Date(Date.now())
    ) {
      setDateErr(true);
      return;
    }

    // validate num tires
    //if (!numTireIn && !numTireOut) {
    //  setTireInErr(true);
    //  setTireOutErr(true);
    //  return;
    //}
    if (numTireIn && !isNormalInteger(numTireIn)) {
      setTireInErr(true);
      return;
    }
    if (numTireOut && !isNormalInteger(numTireOut)) {
      setTireOutErr(true);
      return;
    }

    // validate store, get hauler, check if store has open ticket
    setIsLoading(true);
    API.get("api", "stores/" + storeNum.padStart(4, "0")).then(async (resp) => {
      // store not in ddb
      if (!resp) {
        setStoreNumErr(true);
        setIsLoading(false);
        return;
      }

      // check if any open tickets for store
      let openTicket = false;
      await API.get("api", "open_tickets/" + storeNum.padStart(4, "0")).then(
        (resp) => {
          if (resp) {
            setOpenTicketErr(true);
            setOpenTicketId(resp.ticket_id);
            setIsLoading(false);
            openTicket = true;
          }
        }
      );
      if (openTicket) return;

      // create ticket
      API.post("api", "create_ticket", {
        body: {
          numTireIn: numTireIn,
          numTireOut: numTireOut,
          storeNum: storeNum.padStart(4, "0"),
          legacyId: legacyId,
          hauler: resp.hauler,
          date: date.toISOString(),
          note: note,
          isUrgent: isUrgent,
          role: props.cognito.role,
        },
      }).then(() => props.history.push("/tickets"));
    });
  }

  return (
    props.cognito && ( // TODO: could use loading animation here
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Create Pickup Request
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={10} sm={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={dateErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="Date"
                    inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                    value={date}
                    onChange={(e) => setDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  label="Ticket ID"
                  value={legacyId}
                  onChange={(e) => setLegacyId(e.target.value)}
                  helperText={"Optional legacy ID"}
                  inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                />
              </Grid>
              <Grid item xs={1} sm={3}>
                <span />
              </Grid>
              <Grid item xs={5} sm={3}>
                <TextField
                  required
                  label="Store#"
                  error={storeNumErr}
                  value={storeNum}
                  onChange={(e) => setStoreNum(e.target.value)}
                  helperText={storeNumErr ? "Invalid Store#" : null}
                  inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                />
              </Grid>
              <Grid item xs={6} sm={9}>
                <span />
              </Grid>
              <Grid item xs={10} sm={5}>
                <TextField
                  required
                  error={tireInErr}
                  onChange={(e) => setNumTireIn(e.target.value)}
                  value={numTireIn}
                  id="expDate"
                  label="Tire count (inside)"
                  inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                  helperText="Approx."
                  type="number"
                />
              </Grid>
              <Grid item xs={10} sm={5}>
                <TextField
                  required
                  error={tireOutErr}
                  onChange={(e) => setNumTireOut(e.target.value)}
                  value={numTireOut}
                  label="Tire count (outside)"
                  inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                  helperText="Approx."
                  type="number"
                />
              </Grid>
              {(props.cognito.role === "admin" ||
                props.cognito.role === "account_manager") && (
                <Grid item xs={10} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isUrgent}
                        onChange={() => setIsUrgent(!isUrgent)}
                        color="secondary"
                      />
                    }
                    label="Urgent?"
                    style={{ marginTop: "9px" }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Note"
                  helperText="Additional information for request"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  fullWidth
                  inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                />
              </Grid>
            </Grid>
          </React.Fragment>
          {openTicketErr && (
            <div
              style={{
                fontSize: "1.15rem",
                marginTop: "8px",
                color: "#f44336",
              }}
            >
              There is already an Open/Pending ticket for this store.
              <div
                style={{
                  textDecoration: "underline",
                  fontWeight: "600",
                  color: "#FF7F01",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() =>
                  props.history.push("/ticket?ticket_id=" + openTicketId)
                }
              >
                View Ticket
              </div>
            </div>
          )}
          <div style={{ display: "flex", width: "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => {
                if (isLoading) return;
                props.history.goBack();
              }}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
              </span>
            </span>
            {isLoading ? (
              <CircularProgress
                size={26}
                style={{ marginLeft: "auto", marginTop: "20px" }}
              />
            ) : (
              <Button
                style={{ marginLeft: "auto", marginTop: "10px" }}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                onClick={handleCreate}
              >
                Create
              </Button>
            )}
          </div>
        </Paper>
      </Grid>
    )
  );
}
