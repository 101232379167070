import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { API } from "aws-amplify";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

export default function Stores(props) {
  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            '&$root': {
              '&:hover': {
                cursor: 'pointer', // make row's look clickable
                backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
              },
            }
          },
        },
        MuiIconButton: {
          root: {
            color: '#116466 !important', // primary color
          }
        },
        MUIDataTableHeadCell: { // force column width
          root: {
            '&:nth-child(1)': {
              width: 100
            },
            '&:nth-child(3)': {
              width: 220
            }
          }
        }
      }
    });
  }

  // state
  const [stores, setStores] = useState([]); // raw data
  const [storesTable, setStoresTable] = useState([]); // data for mui table

  const columns = [
    {
      label: "Store#",
      name: "Store#",
      options: {    
        filter: false,
      },
    }, 
    {
      label: "Store Name",
      name: "Store Name",
      options: {    
        filter: false,
      },
    }, 
    {
      label: "State",
      name: "State",
    }, 
    {
      label: "Contact",
      name: "Contact",
      options: {    
        filter: false,
      },
    }, 
    {
      label: "Hauler",
      name: "Hauler",
    }, 
  ];

  const options = {
    filter: true,
    responsive: 'vertical',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (row,index) => {
      // get ticket_id from data (based on index)
      const store_num = stores[index.dataIndex].store_num;
      props.history.push('/store?store_num=' + store_num);
    }
  };

  // init
  useEffect(() => {
    if (!props.cognito) return; // wait for cognito

    let params = {};
    if (props.cognito.role === 'hauler')
      params = {queryStringParameters: {hauler: props.cognito.role_details}};

    // fetch stores from ddb
    API.get('api', 'stores', params).then(resp => {
      setStores(resp);
      // build array for mui table
      const storesTable = resp.map(store => {
        return [
          store.store_num,
          store.store_name,
          store.state,
          store.contact,
          store.hauler
        ];
      });
      setStoresTable(storesTable);
    });
  }, [props.cognito]);

  return (
    <React.Fragment>
      <PageTitle 
        title="Stores"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!storesTable.length ? <Loader isSmall={props.isSmall}/> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Mavis Locations"
              data={storesTable}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
