import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  Person as AccountIcon,
  ExitToApp as LogoutIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { Auth } from "aws-amplify";

// logo
import logo from "../../assets/logo.svg";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";

export default function Header({ props }) {
  var classes = useStyles();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  const roles = {
    account_manager: "Account Manager",
    admin: "Admin",
    store_manager: "Store Manager",
    hauler: "Hauler",
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {props.isSmall && (
          <IconButton
            color="inherit"
            onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}
            className={classNames(
              classes.headerMenuButton,
              classes.headerMenuButtonCollapse
            )}
          >
            {props.isSidebarOpen ? (
              <ArrowBackIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
            )}
          </IconButton>
        )}
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography variant="h3" weight="bold" className={classes.logotype}>
          FacilityChex
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {props.cognito ? props.cognito.email : null}
            </Typography>
            <Typography component="a" color="secondary">
              {props.cognito ? roles[props.cognito.role] : null}
              {props.cognito !== null && props.cognito.role === "store_manager"
                ? " (#" + props.cognito.role_details + ")"
                : null}
              {props.cognito !== null && props.cognito.role === "hauler"
                ? " (" + props.cognito.role_details + ")"
                : null}
              {props.cognito !== null &&
              props.cognito.role === "account_manager"
                ? " (" + props.cognito.role_details + ")"
                : null}
            </Typography>
          </div>
          {
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem
              )}
              onClick={() => {
                props.history.push("/account");
                setProfileMenu(null);
              }}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Account
            </MenuItem>
          }
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
            onClick={async () => {
              await Auth.signOut();
              props.userHasAuthenticated(false);
              props.history.push("/login");
            }}
          >
            <LogoutIcon className={classes.profileMenuIcon} /> Log out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
