import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Link
} from "@material-ui/core";
import { API, Storage } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import Chart from "react-apexcharts";
import moment from "moment";

// libs
import { querystring } from "../../libs/querystring";

// components
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

export default function Store(props) {
  const classes = useStyles();

  //state
  const [store, setStore] = useState(null);
  const [series, setSeries] = useState(null); // chart data
  const [metric, setMetric] = useState("quota"); // metric to display in chart

  async function getS3Image(key) {
    return Storage.get(key, { level: "public", expires: 60 * 60 * 24 });
  }

  useEffect(() => {
    // get store id from url
    const store_num = querystring("store_num");
    // fetch store from ddb
    let cullMetrics = API.get("api", "stores/" + store_num)
      .then(async (resp) => {
        let managers = null;
        if (resp.hasOwnProperty("managers") && resp.managers.length) {
          managers = {};
          resp.managers.forEach((manager) => {
            if (!managers.hasOwnProperty(manager.email))
              managers[manager.email] = {
                start_date: manager.date,
              };
            else managers[manager.email].end_date = manager.date;
          });
        }
        resp.managers = managers;

        // process notes
        if (resp.hasOwnProperty("notes")) {
          resp.notes = await Promise.all(resp.notes.map(async (note, idx) => {
            const tokens = Object.keys(note)[0].split("Z");
            const date = moment(tokens[0] + "Z").format("MM-DD-YY");
            const role = tokens[1];
            let img = null;
            if (tokens[2]) img = await getS3Image(tokens[2]);
            const value = Object.values(note)[0];
            return {
              date: date,
              role: role,
              img: img,
              value: value,
            };
          }));
        }
        setStore(resp);

        const metricsSeries = {
          culls: [
            {
              name: "Avg Cull%",
              data: new Array(12).fill(null),
              denom: new Array(12).fill(0),
            },
          ],
        };
        if (resp.hasOwnProperty("culls")) {
          const yr = new Date().getFullYear();
          resp.culls.forEach((cull) => {
            if (yr.toString() === cull.date.slice(0, 4)) {
              const idx = parseInt(cull.date.slice(5, 7)) - 1;
              if (metricsSeries.culls[0].data[idx])
                metricsSeries.culls[0].data[idx] += cull.cull;
              else metricsSeries.culls[0].data[idx] = cull.cull;
              metricsSeries.culls[0].denom[idx] += 1;
            }
          });
          for (let i = 0; i < 12; i++)
            metricsSeries.culls[0].data[i] /= metricsSeries.culls[0].denom[i];
        }
        return metricsSeries;
      })
      .catch(() => console.log("here")); // TODO: handle error

    // fetch tickets for store
    let params = { queryStringParameters: { store_num: store_num } };
    let tickets = API.get("api", "tickets", params);

    // fetch invoices for store
    params = { queryStringParameters: { store: store_num } };
    API.get("api", "invoices", params).then(async (resp) => {
      tickets = await tickets;
      const metricsSeries = {
        quota: [
          {
            name: "Tires in",
            data: new Array(12).fill(null),
          },
          {
            name: "Tires out",
            data: new Array(12).fill(null),
          },
          {
            name: "Tires out (previous yr.)",
            data: new Array(12).fill(null),
          },
        ],
        approx: [
          {
            name: "Requested",
            data: new Array(12).fill(null),
          },
          {
            name: "Actual",
            data: new Array(12).fill(null),
          },
          {
            name: "No Ticket",
            data: new Array(12).fill(null),
          },
        ],
      };

      const yr = new Date().getFullYear();
      resp.forEach((invoice) => {
        // compute monthly tires in/out
        let count = 0;
        invoice.items.forEach((item) => (count += parseInt(item.num_tire)));
        // check if current year -> update tires in
        if (yr.toString() === invoice.date.slice(0, 4)) {
          const idx = parseInt(invoice.date.slice(5, 7)) - 1;
          if (metricsSeries.quota[1].data[idx])
            metricsSeries.quota[1].data[idx] += count;
          else metricsSeries.quota[1].data[idx] = count;
        }
        // check if previous year -> update tires in (prv yr)
        if ((yr - 1).toString() === invoice.date.slice(0, 4)) {
          const idx = parseInt(invoice.date.slice(5, 7)) - 1;
          if (metricsSeries.quota[2].data[idx])
            metricsSeries.quota[2].data[idx] += count;
          else metricsSeries.quota[2].data[idx] = count;
        }

        // match invoices to tickets
        if (yr.toString() === invoice.date.slice(0, 4)) {
          let isCorr = false;
          tickets.forEach((ticket, idx) => {
            if (ticket.status !== "Closed" && invoice.date > ticket.date)
              isCorr = true;
            else if (
              ticket.closed_date &&
              invoice.date > ticket.date &&
              invoice.date <
                moment(ticket.closed_date).add(1, "days").toISOString()
              // account for invoice being created shortly after closing ticket
            )
              isCorr = true;
            if (isCorr) {
              if (tickets[idx].hasOwnProperty("corrInvoiceCount"))
                tickets[idx].corrInvoiceCount += count;
              else tickets[idx].corrInvoiceCount = count;
            }
          });
          if (!isCorr) {
            // invoice doesn't correspond to ticket
            const idx = parseInt(invoice.date.slice(5, 7)) - 1;
            if (metricsSeries.approx[2].data[idx])
              metricsSeries.approx[2].data[idx] += count;
            else metricsSeries.approx[2].data[idx] = count;
          }
        }
      });
      tickets.forEach((ticket) => {
        if (yr.toString() === ticket.date.slice(0, 4)) {
          const idx = parseInt(ticket.date.slice(5, 7)) - 1;
          // get original approximations
          let num_in = 0;
          if (ticket.num_tire_in_orig)
            num_in = parseInt(ticket.num_tire_in_orig);
          let num_out = 0;
          if (ticket.num_tire_out_orig)
            num_out = parseInt(ticket.num_tire_out_orig);
          if (metricsSeries.approx[0].data[idx])
            metricsSeries.approx[0].data[idx] += num_in + num_out;
          else metricsSeries.approx[0].data[idx] = num_in + num_out;
          // get actual pickup per ticket
          if (ticket.corrInvoiceCount) {
            if (metricsSeries.approx[1].data[idx])
              metricsSeries.approx[1].data[idx] += ticket.corrInvoiceCount;
            else metricsSeries.approx[1].data[idx] = ticket.corrInvoiceCount;
          }
        }
      });
      cullMetrics = await cullMetrics;
      setSeries(Object.assign({}, cullMetrics, metricsSeries));
    });
  }, []);

  const chart_options = {
    chart: {
      type: "bar",
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: "Store Metrics",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: "400",
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 30,
      offsetY: -10,
    },
  };

  return store ? (
    <Grid container className={classes.container}>
      <Grid style={{ justifyContent: "center" }} container spacing={4}>
        <Grid item xs={4}>
          <Paper classes={{ root: classes.paperRoot }}>
            <React.Fragment>
              <Typography variant="h3" gutterBottom>
                Store Info
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Store#
                  </span>{" "}
                  {store.store_num + " - " + store.store_name}
                </Grid>
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    State
                  </span>{" "}
                  {store.state}
                </Grid>
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Address
                  </span>{" "}
                  {store.address}
                </Grid>
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Contact
                  </span>{" "}
                  {store.contact}
                </Grid>
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Hauler
                  </span>{" "}
                  {store.hauler}
                </Grid>
                {store.managers && (
                  <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                    <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                      Managers
                    </span>{" "}
                    {Object.keys(store.managers).map((manager, idx) => (
                      <div
                        key={idx}
                        style={{
                          wordBreak: "break-word",
                          padding: "0px 0px 5px 10px",
                        }}
                      >
                        &nbsp;{manager}&nbsp; (
                        {moment(store.managers[manager].start_date).format(
                          "MM-YY"
                        )}
                        &nbsp;&mdash;&nbsp;
                        {store.managers[manager].end_date &&
                          moment(store.managers[manager].end_date).format(
                            "MM-YY"
                          )}
                        )
                      </div>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Notes
                  </span>
                  {store.hasOwnProperty("notes") &&
                    store.notes.map((note, idx) => (
                      <div
                        key={idx}
                        style={{
                          wordBreak: "break-word",
                          padding: "0px 0px 5px 10px",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>
                          {note.date}
                          <>&nbsp;</>
                        </span>
                        {note.img && (
                          <>
                            (
                            <Link
                              href={note.img}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Image
                            </Link>
                            )&nbsp;
                          </>
                        )}
                        {note.value}
                      </div>
                    ))}
                </Grid>
              </Grid>
            </React.Fragment>
            <div style={{ display: "flex", width: "100%" }}>
              <span
                style={{
                  marginTop: "19px",
                  cursor: "pointer",
                  color: "#FF7F01",
                }}
                onClick={() => props.history.goBack()}
              >
                <BackIcon />
                <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                  Back
                </span>
              </span>
              {
                <Button
                  style={{ marginLeft: "auto", marginTop: "10px" }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.backButton}
                  onClick={() =>
                    props.history.push(
                      "/update_store?store_num=" + store.store_num
                    )
                  }
                >
                  Update
                </Button>
              }
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          {series === null ? <Loader isSmall={props.isSmall} /> : null}
          <Paper
            style={{ maxWidth: "unset" }}
            classes={{ root: classes.paperRoot }}
          >
            <FormControl
              style={{ minWidth: 180, marginBottom: "-40px", zIndex: 100 }}
              className={classes.formControl}
            >
              <InputLabel>Metric</InputLabel>
              <Select
                value={metric}
                onChange={(e) => setMetric(e.target.value)}
              >
                <MenuItem value={"quota"}>Tires In/Out</MenuItem>
                <MenuItem value={"approx"}>Requested vs Actual</MenuItem>
                <MenuItem value={"culls"}>Avg. Cull%</MenuItem>
              </Select>
            </FormControl>
            <div style={{ width: "100%" }}>
              <Chart
                options={chart_options}
                series={series !== null ? series[metric] : []}
                width="100%"
                type={metric === "culls" ? "line" : "bar"}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Loader isSmall={props.isSmall} />
  );
}
