import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { API } from "aws-amplify";

// components
//import Loader from "../../components/Loader/Loader";

// styles
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    paddingTop: theme.spacing(6),
  },
  paperRoot: {
    boxShadow: theme.customShadows.widgetDark,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    maxWidth: 550,
  },
  textRow: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
}));

const roles = {
  account_manager: "Account Manager",
  admin: "Admin",
  store_manager: "Store Manager",
  hauler: "Hauler",
};

export default function Ticket(props) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [storeNum, setStoreNum] = useState("");
  const [storeNumErr, setStoreNumErr] = useState(false);

  useEffect(() => {
    if (props.cognito !== null && props.cognito.role === "store_manager")
      setStoreNum(props.cognito.role_details);
  }, [props]);

  async function updateStore() {
    setStoreNumErr(false);

    // check store num change
    if (props.cognito.role_details !== storeNum.padStart(4, "0")) {
      setIsLoading(true);
      const store = await API.get("api", "stores/" + storeNum.padStart(4, "0"));
      if (!store) {
        setStoreNumErr(true);
        setIsLoading(false);
        return;
      } else {
        const data = {
          UID: props.cognito.UID,
          orig_store: props.cognito.role_details,
          new_store: storeNum.padStart(4, "0"),
          email: props.cognito.email,
          date: new Date(Date.now()).toISOString(),
        };
        await API.post("api", "update_manager", { body: data }).then(() => {
          setIsLoading(false);
          window.location.reload(false);
        });
      }
    }
  }

  return (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Account Info
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "10px" }}>
                Email
              </span>
              {props.cognito ? props.cognito.email : null}
            </Grid>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "10px" }}>
                Role
              </span>
              {props.cognito ? roles[props.cognito.role] : null}
              {props.cognito !== null && props.cognito.role === "store_manager"
                ? " (#" + props.cognito.role_details + ")"
                : null}
              {props.cognito !== null && props.cognito.role === "hauler"
                ? " (" + props.cognito.role_details + ")"
                : null}
              {props.cognito !== null &&
              props.cognito.role === "account_manager"
                ? " (" + props.cognito.role_details + ")"
                : null}
            </Grid>
            {props.cognito !== null && props.cognito.role === "store_manager" && (
              <>
                <Grid
                  item
                  xs={3}
                  style={{ fontSize: "1.15rem", marginTop: "-10px" }}
                >
                  <TextField
                    label="Store#"
                    error={storeNumErr}
                    value={storeNum}
                    onChange={(e) => setStoreNum(e.target.value)}
                    helperText={storeNumErr ? "Invalid Store#" : null}
                    inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                  />
                </Grid>
                <Grid item xs={7} style={{ marginTop: "-5px" }}>
                  {isLoading ? (
                    <CircularProgress
                      size={22}
                      style={{ marginLeft: "auto", marginTop: "20px" }}
                    />
                  ) : (
                    <Button
                      style={{ marginLeft: "auto", marginTop: "10px" }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.backButton}
                      onClick={updateStore}
                    >
                      Update Store#
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
