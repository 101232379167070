import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//import AppliedRoute from "./components/Routes/AppliedRoute";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute";

import Tickets from "./containers/Tickets/Tickets";
import Ticket from "./containers/Tickets/Ticket";
import CreateTicket from "./containers/Tickets/Create";
import UpdateTicket from "./containers/Tickets/Update";
import TicketReports from "./containers/Tickets/Reports";

import Invoices from "./containers/Invoices/Invoices";
import Invoice from "./containers/Invoices/Invoice";
import CreateInvoice from "./containers/Invoices/Create";
import GenerateSummary from "./containers/Invoices/Summary";

import Stores from "./containers/Stores/Stores";
import Store from "./containers/Stores/Store";
import UpdateStore from "./containers/Stores/Update";

import Reports from "./containers/Reports/Reports";

import NotFound from "./containers/NotFound/NotFound";

import Login from "./containers/Login/Login";
import Forgot from "./containers/Login/Forgot";
import Account from "./containers/Login/Account";


export default function Routes({ appProps }) {
  return (
    <Switch>
      <Route exact path="/"> <Redirect to="/tickets" /> </Route>
      <AuthenticatedRoute path="/tickets" exact component={Tickets} appProps={appProps} />
      <AuthenticatedRoute path="/ticket" exact component={Ticket} appProps={appProps} />
      <AuthenticatedRoute path="/create_ticket" exact component={CreateTicket} appProps={appProps} />
      <AuthenticatedRoute path="/update_ticket" exact component={UpdateTicket} appProps={appProps} />
      <AuthenticatedRoute path="/ticket_report" exact component={TicketReports} appProps={appProps} />

      <AuthenticatedRoute path="/stores" exact component={Stores} appProps={appProps} />
      <AuthenticatedRoute path="/store" exact component={Store} appProps={appProps} />
      <AuthenticatedRoute path="/update_store" exact component={UpdateStore} appProps={appProps} />

      <AuthenticatedRoute path="/invoices" exact component={Invoices} appProps={appProps} />
      <AuthenticatedRoute path="/invoice" exact component={Invoice} appProps={appProps} />
      <AuthenticatedRoute path="/create_invoice" exact component={CreateInvoice} appProps={appProps} />
      <AuthenticatedRoute path="/generate_summary" exact component={GenerateSummary} appProps={appProps} />

      <AuthenticatedRoute path="/reports" exact component={Reports} appProps={appProps} />

      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/forgot" exact component={Forgot} appProps={appProps} />

      <AuthenticatedRoute path="/account" exact component={Account} appProps={appProps} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
