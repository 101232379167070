import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API, Storage } from "aws-amplify";

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring";

// styles
import useStyles from "./styles";

export default function Update(props) {
  const classes = useStyles();

  // TODO: put in DDB
  const haulers = [
    "No Hauler",
    "Ben Tire",
    "Black to Green",
    "Emanuel",
    "Best Haulers",
    "Liberty",
    "Liberty Lakin",
    "tyrex",
    "S&J Recycling Inc",
  ];

  //state
  const [store, setStore] = useState(null);
  const [contact, setContact] = useState("");
  const [hauler, setHauler] = useState("");
  const [contactErr, setContactErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cull, setCull] = useState("");
  const [cullErr, setCullErr] = useState(false);
  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);
  const [note, setNote] = useState("");
  const [img, setImg] = useState(null);
  const [imgURL, setImgURL] = useState(null);
  const [noteErr, setNoteErr] = useState(false);

  useEffect(() => {
    // get store id from url
    const store_num = querystring("store_num");
    // fetch store from ddb
    API.get("api", "stores/" + store_num)
      .then((resp) => {
        setStore(resp);
        setContact(resp.contact);
        setHauler(resp.hauler);
      })
      .catch(() => console.log("here")); // TODO: handle error
  }, []);

  function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  function handleUpdate() {
    // reset form errors
    setContactErr(false);
    setDateErr(false);
    setCullErr(false);
    setNoteErr(false);

    if (!contact) {
      setContactErr(true);
      return;
    }

    // get date
    if (!date || date.toString() === "Invalid Date") {
      setDateErr(true);
      return;
    }

    // validate num tires
    if (cull && !isNormalInteger(cull)) {
      setCullErr(true);
      return;
    }

    // check if image and missing note
    if (img && !note) {
      setNoteErr(true);
      return;
    }

    // update store
    const data = { contact: contact, hauler: hauler };
    if (cull) {
      data.cull = parseInt(cull);
      data.date = date.toISOString();
    }
    if (note) {
      data.note = note;
      data.role = props.cognito.role;
      data.note_date = new Date(Date.now()).toISOString();
      data.img = img;
    }
    setIsLoading(true);
    API.post("api", "update_store/" + store.store_num, {
      body: data,
    }).then(() => props.history.push("/stores"));
  }

  function handleFileChange(event) {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) return;
    if (file && file.size > 4000000) {
      alert(`Please pick a file smaller than 4 MB.`);
      return;
    }
    setIsLoading(true);
    const key = Math.random().toString(16).substr(2, 9);
    uploadS3Image(file, key).then(async () => {
      const url = await getS3Image(key);
      setIsLoading(false);
      setImgURL(url);
      setImg(key);
    });
  }

  async function uploadS3Image(file, key) {
    const settings = {
      contentType: file.type,
      level: "public",
    };

    return Storage.vault.put(key, file, settings);
  }

  async function getS3Image(key) {
    return Storage.get(key, { level: "public", expires: 60 * 60 * 24 });
  }

  return store ? (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Store Info
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                Store#
              </span>{" "}
              {store.store_num + " - " + store.store_name}
            </Grid>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                State
              </span>{" "}
              {store.state}
            </Grid>
            <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
              <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                Address
              </span>{" "}
              {store.address}
            </Grid>
            {props.cognito !== null && props.cognito.role !== "admin" && (
              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Hauler
                </span>{" "}
                {store.hauler}
              </Grid>
            )}
          </Grid>
        </React.Fragment>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "10px", marginLeft: "8px" }}
        >
          {props.cognito !== null && props.cognito.role === "admin" && (
            <Grid item xs={7} sm={5}>
              <FormControl
                style={{ minWidth: 140 }}
                className={classes.formControl}
              >
                <InputLabel>Hauler</InputLabel>
                <Select
                  value={hauler}
                  onChange={(e) => setHauler(e.target.value)}
                >
                  {haulers.map((hauler) => (
                    <MenuItem key={hauler} value={hauler}>
                      {hauler}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={7} sm={5}>
            <TextField
              required
              error={contactErr}
              onChange={(e) => setContact(e.target.value)}
              value={contact}
              label="Contact"
              inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ fontSize: "1.15rem", marginBottom: "-10px" }}
          >
            <span
              style={{
                fontWeight: "600",
                marginLeft: "-6px",
              }}
            >
              Report Cull
            </span>{" "}
          </Grid>
          <Grid item sm={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                error={dateErr}
                disableToolbar
                variant="inline"
                format="MM-dd-yyyy"
                label="Date"
                inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
                InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
                value={date}
                onChange={(e) => setDate(e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={2}>
            <TextField
              error={cullErr}
              onChange={(e) => setCull(e.target.value)}
              value={cull}
              label="Cull %"
              inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ fontSize: "1.15rem", marginBottom: "-10px" }}
          >
            <span
              style={{
                fontWeight: "600",
                marginLeft: "-6px",
              }}
            >
              Add Note
            </span>{" "}
          </Grid>
          <Grid item xs={9}>
            <TextField
              label="Note"
              error={noteErr}
              //helperText="Additional update information"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              fullWidth
              inputProps={{ style: { fontSize: "1.15rem" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: "1.15rem" } }} // font size of input label
            />
          </Grid>
          <Grid item xs={3}>
            {!isLoading ? (
              !imgURL ? ( // hide button and image if loading
                <label htmlFor="raised-button-file">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    name="raised-button-file"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button
                    style={{ marginLeft: "auto", marginTop: "35px" }}
                    variant="contained"
                    color="primary"
                    size="small"
                    component="span"
                  >
                    Add image
                  </Button>
                </label>
              ) : (
                <img src={imgURL} alt="img" style={{ width: "100%" }} />
              )
            ) : null}
          </Grid>
        </Grid>
        <div style={{ display: "flex", width: "100%" }}>
          <span
            style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress
              size={26}
              style={{ marginLeft: "auto", marginTop: "20px" }}
            />
          ) : (
            <Button
              style={{ marginLeft: "auto", marginTop: "10px" }}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={handleUpdate}
            >
              Submit
            </Button>
          )}
        </div>
      </Paper>
    </Grid>
  ) : (
    <Loader isSmall={props.isSmall} />
  );
}
