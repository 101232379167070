import React from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Receipt as TicketIcon,
  QuestionAnswer as SupportIcon,
  ArrowBack as ArrowBackIcon,
  Description as InvoiceIcon,
  Store as StoreIcon,
  Assessment as ReportIcon
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

function Sidebar({ props, location }) {
  var classes = useStyles();

  const structure = [
    { id: 2, label: "Tickets", link: "/tickets", icon: <TicketIcon /> },
    { id: 3, label: "Stores", link: "/stores", icon: <StoreIcon /> },
    { id: 4, label: "Invoices", link: "/invoices", icon: <InvoiceIcon /> },
    { id: 5, label: "Reports", link: "/reports", icon: <ReportIcon /> },
    { id: 6, type: "divider" },
    { id: 7, type: "title", label: "HELP" },
    { id: 8, label: "Support", link: "email", icon: <SupportIcon /> },
  ];

  // remove stores and invoices and reports for store managers
  if (props.cognito && props.cognito.role === 'store_manager')
    structure.splice(1, 3);

  // remove invoices and reports for store managers
  if (props.cognito && props.cognito.role === 'account_manager')
    structure.splice(2, 1);

  // remove reports for haulers
  if (props.cognito && props.cognito.role === 'hauler'){
    if (props.cognito.role_details === 'tyrex')
      structure.splice(2, 2);
    else
      structure.splice(3, 1);
  }

  return (
    <Drawer
      variant={!props.isSmall ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.isSidebarOpen,
        [classes.drawerClose]: !props.isSidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.isSidebarOpen,
          [classes.drawerClose]: !props.isSidebarOpen,
        }),
      }}
      open={props.isSidebarOpen}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={props.isSidebarOpen}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

export default withRouter(Sidebar);
