import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { API } from "aws-amplify";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import moment from "moment";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

export default function Reports(props) {
  const classes = useStyles();

  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            "&$root": {
              "&:hover": {
                backgroundColor: "rgba(74, 74, 74, 0.08)", // force bg color
              },
            },
          },
        },
        MuiIconButton: {
          root: {
            color: "#116466 !important", // primary color
          },
        },
      },
    });
  }

  // state
  const [tickets, setTickets] = useState(null); // raw data
  const [ticketsTable, setTicketsTable] = useState([]); // data for mui table
  const [series, setSeries] = useState(null); // chart data
  const [metric, setMetric] = useState("tickets"); // metric to display in chart

  const columns = [
    {
      label: "Hauler",
      name: "Hauler",
    },
    {
      label: "Metrics",
      name: "Metrics",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const idx = tableMeta.currentTableData[tableMeta.rowIndex].index;
          const data = tableMeta.tableData[idx][2];
          return (
            <React.Fragment>
              <div>{value}</div>
              <div>{data.openPercent.toFixed(2)} ticket/store ratio</div>
              <div>{data.noETA} missing ETA</div>
              <div>{data.overETA} past ETA</div>
              {data.eta_avg_count ? (
                <div>{data.eta_avg.toFixed(1)} days to ETA (avg.)</div>
              ) : null}
              <div>{data.avg.toFixed(1)} days/ticket (avg.)</div>
            </React.Fragment>
          );
        },
      },
    },
  ];

  const table_options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    responsive: "vertical",
    sortOrder: {
      name: "Num. Tickets",
      direction: "asc",
    },
    customFooter: () => <React.Fragment></React.Fragment>,
    filterType: "checkbox",
    selectableRows: "none",
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        a = a.data[colIndex] || "";
        b = b.data[colIndex] || "";
        if (order === "asc")
          return a.toString().localeCompare(b, undefined, { numeric: true });
        else return b.toString().localeCompare(a, undefined, { numeric: true });
      });
    },
  };

  const chart_options = {
    chart: {
      type: "area",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Historical Metrics",
      align: "left",
      style: {
        fontSize: "21px",
        fontWeight: "400",
      },
    },
    yaxis: {
      // title: {
      //   text: "Num. Tickets",
      //   style: {
      //     fontSize: "14px",
      //     fontWeight: "400",
      //   },
      // },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "MMM dd",
          hour: "HH:mm",
        },
      },
    },
    stroke: {
      width: 2.5,
    },
    tooltip: {
      shared: true,
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const metric = w.config.series[seriesIndex].name.split("|")[1];
          if (metric === "tickets") {
            if (!w.config.series[seriesIndex].data.length) return null;
            const desc =
              w.config.series[seriesIndex].data[dataPointIndex].description;
            return (
              value +
              " open (" +
              desc.pending +
              " pending, " +
              desc.urgent +
              " urgent)"
            );
          } else if (metric === "ratio") {
            return value.toFixed(2) + " ticket/store ratio";
          } else if (metric === "noETA") {
            return value.toFixed(0) + " missing ETA";
          } else if (metric === "overETA") {
            return value.toFixed(0) + " past ETA";
          } else if (value && metric === "avgETA") {
            return value.toFixed(1) + " days to ETA (avg.)";
          } else if (metric === "avg") {
            return value.toFixed(1) + " days/ticket (avg.)";
          }
        },
        title: {
          formatter: (seriesName) => seriesName.split("|")[0],
        },
      },
      x: {
        format: "MMM dd",
      },
    },
    legend: {
      formatter: function (seriesName, opts) {
        return seriesName.split("|")[0];
      },
    },
  };

  // init
  useEffect(() => {
    // fetch stores from ddb
    let stores = API.get("api", "stores");
    // fetch tickets from ddb
    API.get("api", "tickets").then(async (resp) => {
      setTickets(resp);
      const haulers = {};
      resp
        .filter((ticket) => ticket.status !== "Closed")
        .forEach((ticket) => {
          if (!haulers.hasOwnProperty(ticket.hauler)) {
            haulers[ticket.hauler] = {
              open: 0,
              urgent: 0,
              pending: 0,
              noETA: 0,
              overETA: 0,
              avg: 0,
              numStore: 0,
              eta_avg: 0,
              eta_avg_count: 0,
            };
          }
          haulers[ticket.hauler].open += 1;
          haulers[ticket.hauler].avg += moment().diff(
            moment(ticket.date),
            "days",
            true
          );
          if (ticket.urgency === "Urgent") haulers[ticket.hauler].urgent += 1;
          if (ticket.status === "Pending") haulers[ticket.hauler].pending += 1;
          if (!ticket.eta) haulers[ticket.hauler].noETA += 1;
          if (ticket.eta && moment().isAfter(ticket.eta))
            haulers[ticket.hauler].overETA += 1;
          if (ticket.eta_set) {
            haulers[ticket.hauler].eta_avg += moment(ticket.eta_set).diff(
              moment(ticket.date),
              "days",
              true
            );
            haulers[ticket.hauler].eta_avg_count += 1;
          }
        });
      stores = await stores;
      stores.forEach((store) => {
        if (haulers.hasOwnProperty(store.hauler))
          haulers[store.hauler].numStore += 1;
      });

      // build array for mui table
      const ticketsTable = Object.keys(haulers).map((hauler) => {
        haulers[hauler].openPercent =
          haulers[hauler].open / haulers[hauler].numStore;
        haulers[hauler].avg /= haulers[hauler].open; // avg days for open tickets (currently)
        haulers[hauler].eta_avg /= haulers[hauler].eta_avg_count; // avg days for hauler to give eta (currently open)
        return [
          hauler,
          haulers[hauler].open +
            " open (" +
            haulers[hauler].pending +
            " pending, " +
            haulers[hauler].urgent +
            " urgent)",
          haulers[hauler],
        ];
      });
      setTicketsTable(ticketsTable);
    });

    // fetch reports from ddb
    API.get("api", "reports").then((resp) => {
      const metrics = ["tickets", "ratio", "noETA", "overETA", "avg", "avgETA"];
      const metricsSeries = {};
      // get fixed list of hauler names
      const hauler_names = Object.keys(resp[0].open_tickets);
      metrics.forEach((metric) => {
        metricsSeries[metric] = hauler_names.map((hauler) => {
          return {
            name: hauler + "|" + metric,
            data: [],
          };
        });
      });
      // sort reports by date (asc)
      resp.sort((a, b) => (a.date > b.date ? 1 : -1));
      resp.forEach((report) => {
        const date = new Date(report.date.slice(0, 10));
        const time = date.getTime();
        hauler_names.forEach((hauler, idx) => {
          metricsSeries.tickets[idx].data.push({
            x: time,
            y: report.open_tickets[hauler].open,
            description: {
              pending: report.open_tickets[hauler].pending,
              urgent: report.open_tickets[hauler].urgent,
            },
          });
          if (report.open_tickets[hauler].hasOwnProperty("openPercent"))
            metricsSeries.ratio[idx].data.push({
              x: time,
              y: report.open_tickets[hauler].openPercent.toFixed(2),
            });
          if (report.open_tickets[hauler].hasOwnProperty("noETA"))
            metricsSeries.noETA[idx].data.push({
              x: time,
              y: report.open_tickets[hauler].noETA,
            });
          if (report.open_tickets[hauler].hasOwnProperty("overETA"))
            metricsSeries.overETA[idx].data.push({
              x: time,
              y: report.open_tickets[hauler].overETA,
            });
          if (report.open_tickets[hauler].hasOwnProperty("avg"))
            metricsSeries.avg[idx].data.push({
              x: time,
              y: report.open_tickets[hauler].avg.toFixed(1),
            });
          if (report.open_tickets[hauler].hasOwnProperty("eta_avg") && report.open_tickets[hauler].eta_avg_count)
            metricsSeries.avgETA[idx].data.push({
              x: time,
              y: report.open_tickets[hauler].eta_avg.toFixed(1),
            });
        });
      });
      setSeries(metricsSeries);
    });
  }, []);

  return (
    <React.Fragment>
      <PageTitle title="Reports" />
      <Grid style={{ justifyContent: "center" }} container spacing={4}>
        <Grid item xs={4}>
          {tickets === null ? <Loader isSmall={props.isSmall} /> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Current Metrics"
              data={ticketsTable}
              columns={columns}
              options={table_options}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={8}>
          {series === null ? <Loader isSmall={props.isSmall} /> : null}
          <Paper classes={{ root: classes.paperRoot }}>
            <FormControl
              style={{ minWidth: 180, marginBottom: "-40px", zIndex: 100 }}
              className={classes.formControl}
            >
              <InputLabel>Metric</InputLabel>
              <Select
                value={metric}
                onChange={(e) => setMetric(e.target.value)}
              >
                <MenuItem value={"tickets"}>Tickets</MenuItem>
                <MenuItem value={"ratio"}>Tickets/Store Ratio</MenuItem>
                <MenuItem value={"noETA"}>No ETA</MenuItem>
                <MenuItem value={"overETA"}>Past ETA</MenuItem>
                <MenuItem value={"avgETA"}>Days to ETA</MenuItem>
                <MenuItem value={"avg"}>Days/Ticket (avg.)</MenuItem>
              </Select>
            </FormControl>
            <div style={{ width: "100%" }}>
              <Chart
                options={chart_options}
                series={series !== null ? series[metric] : []}
                width="100%"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
