import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Add as AddIcon, NoteAdd as SummaryIcon } from "@material-ui/icons";
import { API } from "aws-amplify";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import moment from 'moment';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

export default function Invoices(props) {
  const classes = useStyles();
  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            '&$root': {
              '&:hover': {
                cursor: 'pointer', // make row's look clickable
                backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
              },
            }
          },
        },
        MuiIconButton: {
          root: {
            color: '#116466 !important', // primary color
          }
        },
        MUIDataTableHeadCell: { // force column width
          root: {
            '&:nth-child(1)': {
              width: 150
            },
            '&:nth-child(2)': {
              width: 150
            },
            '&:nth-child(3)': {
              width: 200
            }
          }
        }
      }
    });
  }

  // state
  const [invoices, setInvoices] = useState(null); // raw data
  const [invoicesTable, setInvoicesTable] = useState([]); // data for mui table

  const columns = [
    {
      label: "Date",
      name: "Date",
      options: {    
        filter: false,
        customBodyRender: date => (
          <React.Fragment>
            {moment(date).format('MM-DD-YYYY')}
          </React.Fragment>
        )
      },
    }, 
    {
      label: "Store#",
      name: "Store#",
      options: {    
        filter: false
      },
    }, 
    {
      label: "Hauler",
      name: "Hauler"
    }, 
    {
      label: "Count",
      name: "Count",
      options: {    
        filter: false,
        customBodyRender: items => {
          let count = 0;
          items.forEach(item => count += parseInt(item.num_tire));
          return (
            <React.Fragment>
              {count}
            </React.Fragment>
          );
        }
      },
    }, 
  ];

  const options = {
    filter: true,
    responsive: 'vertical',
    sortOrder: {
      name: 'Date',
      direction: 'desc'
    },
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (row,index) => {
      // get invoice_id from data (based on index)
      const invoice_id = invoices[index.dataIndex].invoice_id;
      props.history.push('/invoice?invoice_id=' + invoice_id);
    }
  };

  // init
  useEffect(() => {
    if (!props.cognito) return; // wait for cognito

    let params = {};
    if (props.cognito.role === 'hauler')
      params = {queryStringParameters: {hauler: props.cognito.role_details}};

    // fetch invoices from ddb
    API.get('api', 'invoices', params).then(resp => {
      setInvoices(resp);
      // build array for mui table
      const invoicesTable = resp.map(invoice => {
        return [
          invoice.date,
          invoice.store,
          invoice.hauler,
          invoice.items
        ];
      });
      setInvoicesTable(invoicesTable);
    });
  }, [props.cognito]);

  const buttons = [
    {
      text: "Create Invoice",
      icon: <AddIcon className={classes.icon} />,
      func: () => props.history.push('/create_invoice')
    },
    {
      text: "Generate Summary",
      icon: <SummaryIcon className={classes.icon} />,
      func: () => props.history.push('/generate_summary')
    },
  ];

  return (
    <React.Fragment>
      <PageTitle 
        title="Invoices" 
        buttons={buttons}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {invoices === null ? <Loader isSmall={props.isSmall}/> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Pickup Invoices"
              data={invoicesTable}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
